










































































































































































import Vue from 'vue'
import { Pagination } from '../../../../components'
import KnelpuntInformation from './@components/KnelpuntInformation.vue'
import KnelpuntComment from './@components/KnelpuntComment.vue'
import KnelpuntProblem from './@components/KnelpuntProblem.vue'
import KnelpuntAdvice from './@components/KnelpuntAdvice.vue'
import KnelpuntSolution from './@components/KnelpuntSolution.vue'
import { KnelpuntDetailType, KnelpuntDetailTypes } from "../KnelpuntDetailTypes"
import { mapGetters } from 'vuex'
import { Knelpunt, KnelpuntDetail, KnelpuntdetailService, knelpuntstatusupdate, TracedetailService } from '../../../../@gen'
import { titleCaseToSentence } from '../Helper'
import { KnelpuntExtended } from '../../../../@types/KnelpuntExtended'
import KnelpuntenanalyseChangeKnelpuntStatusPopover from './KnelpuntenanalyseChangeKnelpuntStatusPopover.vue'


export default Vue.extend({
  components: {
    Pagination,
    KnelpuntInformation,
    KnelpuntComment,
    KnelpuntProblem,
    KnelpuntAdvice,
    KnelpuntSolution,
    KnelpuntenanalyseChangeKnelpuntStatusPopover,
  },
  data () {
    return {
      infoPagination: 0,
      KnelpuntDetailTypes: KnelpuntDetailTypes,
      item: KnelpuntDetailTypes['Informatie'],
      knelpuntdetail: {} as KnelpuntDetail,
      comments: [] as boolean[],
      problems: [] as boolean[],
      advice: [] as boolean[],
      solutions: [] as boolean[],
      updateKnelpuntStatusKnelpunt: null as KnelpuntExtended | null,
      updateKnelpuntStatusRecommendations: [] as string[],
    }
  },
  
  computed: {
    ...mapGetters([
      "filteredSelectedKnelpunten",
    ]),
    knelpuntenCount(): number {
      return this.filteredSelectedKnelpunten.length;
    },
    knelpunt(): KnelpuntExtended | null {
      if (this.infoPagination != 0 && this.filteredSelectedKnelpunten.length > 0) {
        return this.filteredSelectedKnelpunten[this.infoPagination-1];
      } else {
        return null;
      }
    },
  },
  watch: {
    knelpunt(newKnelpunt: KnelpuntExtended) {
      this.stopKnelpuntStatusUpdate()
      if (newKnelpunt != null)  {
        if (newKnelpunt.knelpunt.detailRef != undefined) {
          KnelpuntdetailService.knelpuntdetail({id: newKnelpunt.knelpunt.detailRef, xFields: "*"})
            .then((knelpuntdetail: KnelpuntDetail) => {
              if (this.knelpunt != null && this.knelpunt.knelpunt.detailRef! == newKnelpunt.knelpunt.detailRef!) {
                this.knelpuntdetail = knelpuntdetail;
              }
            })
        } else {
          this.knelpuntdetail = {};
          this.comments = [];
          this.problems = [];
          this.advice = [];
          this.solutions = [];
        }
      } else {
        this.knelpuntdetail = {};
        this.comments = [];
        this.problems = [];
        this.advice = [];
        this.solutions = [];
      }
    }
  },
  methods: {
    viewKnelpuntInMap() {
      this.$emit("viewKnelpunt", this.knelpunt)
    },
    viewKnelpunt(knelpunt: KnelpuntExtended) {
      const knelpuntIndex = this.filteredSelectedKnelpunten.findIndex((x: KnelpuntExtended) => x == knelpunt);
      if (knelpuntIndex != -1) 
        this.infoPagination = knelpuntIndex + 1;
    },
    scrollTo(id: string) {
      const element: HTMLElement | null = document.getElementById(id)
      if (element != null)
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    },
    titleCaseToSentence: titleCaseToSentence,
    addDetail(detail: string) {
      const delay = 150;
      //NOTE: restricted to creation of one new knelpunt detail per type
      if (detail == "Opmerking") {
        if (this.comments.length < 1) {
          this.comments.push(true)
          setTimeout(() => this.scrollTo(detail + this.getCommentCreationIndex(this.comments.length - 1)), delay);
        }
      } else if (detail == "Probleem") {
        if (this.problems.length < 1) {
          this.problems.push(true)
          setTimeout(() => this.scrollTo(detail + this.getProblemCreationIndex(this.problems.length - 1)), delay);
        }
      } else if (detail == "Advies") {
        if (this.advice.length < 1) {
          this.advice.push(true)
          setTimeout(() => this.scrollTo(detail + this.getAdviceCreationIndex(this.advice.length - 1)), delay);
        }
      } else if (detail == "Oplossing") {
        if (this.solutions.length < 1) {
          this.solutions.push(true)
          setTimeout(() => this.scrollTo(detail + this.getSolutionCreationIndex(this.solutions.length - 1)), delay);
        }
      }
    },
    //Comment methods
    saveCommentCreation(index: number, newComment: string) {
      if (this.knelpuntdetail.comments == undefined) 
        this.knelpuntdetail.comments = [];
      this.knelpuntdetail.comments.splice(index, 0, newComment)
      this.comments.splice(index, 1)
      if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
          Knelpunt.status.IN_OPVOLGING_IN_BESPREKING, Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST, Knelpunt.status.AFGEHANDELD_GEEN_BEZWAAR, Knelpunt.status.AFGEHANDELD_OPLOSSING_AFGESTEMD
        ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    cancelCommentCreation(index: number) {
      this.comments.splice(index, 1)
    },
    deleteComment(index: number) {
      if (this.knelpuntdetail.comments != undefined)
        this.knelpuntdetail.comments.splice(index, 1)
      if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
          Knelpunt.status.OPEN_NOG_GEEN_ACTIE_ONDERNOMEN, Knelpunt.status.IN_OPVOLGING_IN_BESPREKING, Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST
        ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    getCommentCreationIndex(index): number {
      return this.knelpuntdetail.comments == undefined ? index : index + this.knelpuntdetail.comments.length;
    },
    //Problem methods
    saveProblemCreation(index: number, newProblem: string) {
      if (this.knelpuntdetail.problems == undefined) 
        this.knelpuntdetail.problems = [];
      this.knelpuntdetail.problems.splice(index, 0, newProblem)
      this.problems.splice(index, 1)
      if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
          Knelpunt.status.IN_OPVOLGING_IN_BESPREKING, Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST
        ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    cancelProblemCreation(index: number) {
      this.problems.splice(index, 1)
    },
    deleteProblem(index: number) {
      if (this.knelpuntdetail.problems != undefined)
        this.knelpuntdetail.problems.splice(index, 1)
      if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
          Knelpunt.status.OPEN_NOG_GEEN_ACTIE_ONDERNOMEN, Knelpunt.status.IN_OPVOLGING_IN_BESPREKING, Knelpunt.status.AFGEHANDELD_GEEN_BEZWAAR, Knelpunt.status.AFGEHANDELD_OPLOSSING_AFGESTEMD
        ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    getProblemCreationIndex(index): number {
      return this.knelpuntdetail.problems == undefined ? index : index + this.knelpuntdetail.problems.length;
    },
    //Advice methods
    saveAdviceCreation(index: number, newAdvice: string) {
      if (this.knelpuntdetail.advice == undefined) 
        this.knelpuntdetail.advice = [];
      this.knelpuntdetail.advice.splice(index, 0, newAdvice)
      this.advice.splice(index, 1)
      if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
          Knelpunt.status.IN_OPVOLGING_IN_BESPREKING, Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST, Knelpunt.status.AFGEHANDELD_OPLOSSING_AFGESTEMD
        ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    cancelAdviceCreation(index: number) {
      this.advice.splice(index, 1)
    },
    deleteAdvice(index: number) {
      if (this.knelpuntdetail.advice != undefined)
        this.knelpuntdetail.advice.splice(index, 1)
      if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
          Knelpunt.status.OPEN_NOG_GEEN_ACTIE_ONDERNOMEN, Knelpunt.status.IN_OPVOLGING_IN_BESPREKING, Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST
        ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    getAdviceCreationIndex(index): number {
      return this.knelpuntdetail.advice == undefined ? index : index + this.knelpuntdetail.advice.length;
    },
    //Solution methods
    saveSolutionCreation(index: number, newSolution: string) {
      if (this.knelpuntdetail.solutions == undefined) 
        this.knelpuntdetail.solutions = [];
      this.knelpuntdetail.solutions.splice(index, 0, newSolution)
      this.solutions.splice(index, 1)
      if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
            Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST, Knelpunt.status.AFGEHANDELD_OPLOSSING_AFGESTEMD
          ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    cancelSolutionCreation(index) {
      this.solutions.splice(index, 1)
    },
    deleteSolution(index) {
      if (this.knelpuntdetail.solutions != undefined)
        this.knelpuntdetail.solutions.splice(index, 1)
       if (this.knelpunt != null) {
        const statusRecommendations: string[] = [
            Knelpunt.status.OPEN_NOG_GEEN_ACTIE_ONDERNOMEN, Knelpunt.status.IN_OPVOLGING_IN_BESPREKING, Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST
          ].filter((status: Knelpunt.status) => status != this.knelpunt!.knelpunt.status)
        this.openKnelpuntStatusUpdate(statusRecommendations)
      }
    },
    getSolutionCreationIndex(index): number {
      return this.knelpuntdetail.solutions == undefined ? index : index + this.knelpuntdetail.solutions.length;
    },
    // ---
    stopKnelpuntStatusUpdate() {
      this.updateKnelpuntStatusKnelpunt = null;
      this.updateKnelpuntStatusRecommendations = [];
    },
    openKnelpuntStatusUpdate(statusRecommendations: string[]) {
      this.updateKnelpuntStatusKnelpunt = this.knelpunt;
      this.updateKnelpuntStatusRecommendations = statusRecommendations;
    }
  },
})
