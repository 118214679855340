import DashboardLayout from "../views/dashboard/@layout/DashboardLayout.vue";

import HelloWorld from "../@components/HelloWorld.vue";
import ProjectsView from "../views/dashboard/ProjectsView.vue";
import KnelpuntenanalyseView from "../views/dashboard/knelpuntenanalyse/KnelpuntenanalyseView.vue"
import ErrorView from "../views/ErrorView.vue"

import { authGuard } from "../auth/authGuard";

const routes: Array<any> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    beforeEnter: authGuard,
    redirect: '/dashboard/projects',
    children: [
      {
        path: "projects",
        name: "Projects",
        component: ProjectsView
      },
      {
        path: "project/:projectID",
        redirect: 'naar corect type project', // TODO: naar correcte type project redirecten
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          // Alle type projecten:
          {
            path: 'knelpuntenanalyse',
            name: "Knelpuntenanalyse",
            component: KnelpuntenanalyseView 
          },
          {
            path: 'knelpuntenanalyse/settings',
            name: "Settings",
            component: HelloWorld, //KnelpuntenanalyseSettingsLayout
            // children: [
            //   //faces
            // ] 
          }
        ]
      }
    ]
  },
  {
    path: '/error',
    component: ErrorView
  },
  // 404 page not found
  // TODO: setup 404 page
  {
    path: '*', 
    redirect: '/dashboard'
  },
];

export default routes;

/*
  dashboard/ -> dashboard/projects
    /customers* als tijd over is
    /projects  "meer"
      /:id
        /knelpuntenanalyse
        /'etc' (bv. faalkansbepaling)
    /assets
*/