import Vue from "vue";
import { Knelpunt, KnelpuntdetailService, Knelpuntenanalyse, KnelpuntenanalyseService, knelpuntstatusupdate, Project, ProjectsService, SurroundingTrace, Trace, TraceDetail, TracedetailService } from "@/@gen"
import { Module } from "vuex";
import { KnelpuntExtended } from "@/@types/KnelpuntExtended";
import { ProjectLink } from "./project";

// interface KnelpuntenanalyseModuleState {
//   project?: Project;
//   knelpuntenanalyse?: Knelpuntenanalyse;
//   selectedTraces: string[];
//   traceDetails: { [tracename: string]: TraceDetail; }
//   retreivedTraceDetails: string[]
// }

export const KnelpuntenanalyseModule: Module<any, any> = {
  state: () => ({
    project: null,
    knelpuntenanalyse: null,
    selectedTraces: [],
    traceDetails: {}, 
    retreivedTraceDetails: [],
    // Selection:
    filterBounds: null,
    // Filter:
    filterKnelpuntThemes: [],
    filterKnelpuntRisks: [],
    involvedNetbeheerdersOptions: [],
    involvedThemeOptions: [],
    filterNetbeheerders: [],
    filterMinWeight: "",
    filterMaxWeight: "",
    filterStatuses: [],
    // ------
  }),
  mutations: {
    addProject(state, project: Project) {
      state.project = project;
    },
    addKnelpuntenanalyse(state, knelpuntenanalyse: Knelpuntenanalyse) {
      state.knelpuntenanalyse = knelpuntenanalyse;
    },
    addKnelpuntDetail(state: any, traceDetail: { name: string, detail: TraceDetail } ) {
      Vue.set(state.traceDetails, traceDetail.name, traceDetail.detail)
      //instead of: state.traceDetails[traceDetail.name] = traceDetail.detail;
      
      //add unique netbeheerders and theme to options:
      traceDetail.detail.knelpunten.forEach((knelpunt: Knelpunt) => {
        //Netbeheer
        if (knelpunt.netbeheerder != undefined) {
          if (!state.involvedNetbeheerdersOptions.includes(knelpunt.netbeheerder)) 
            state.involvedNetbeheerdersOptions.push(knelpunt.netbeheerder);
        }
        //Theme
        if (knelpunt.theme != undefined) {
          if (!state.involvedThemeOptions.includes(knelpunt.theme)) 
            state.involvedThemeOptions.push(knelpunt.theme);
        }
      });
    },
    updateSelectedTraces(state, selectedTraces) {
      state.selectedTraces = selectedTraces;
    },
    // Filter:
    updateClearFilters(state) {
      if (state.filterKnelpuntThemes.length > 0) state.filterKnelpuntThemes = [];
      if (state.filterKnelpuntRisks.length > 0) state.filterKnelpuntRisks = [];
      if (state.filterNetbeheerders.length > 0) state.filterNetbeheerders = [];
      if (state.filterMinWeight != "") state.filterMinWeight = "";
      if (state.filterMaxWeight != "") state.filterMaxWeight = "";
      if (state.filterStatuses.length > 0) state.filterStatuses = [];
    },
    updateFilterKnelpuntThemes(state, filterKnelpuntThemes: string[]) {
      state.filterKnelpuntThemes = filterKnelpuntThemes;
    },
    updateFilterKnelpuntRisks(state, filterKnelpuntRisks: string[]) {
      state.filterKnelpuntRisks = filterKnelpuntRisks;
    },
    updateFilterNetbeheerders(state, filterNetbeheerders: string[]) {
      state.filterNetbeheerders = filterNetbeheerders;
    },
    updateFilterMinWeight(state, filterMinWeight: string) {
      state.filterMinWeight = filterMinWeight;
    },
    updateFilterMaxWeight(state, filterMaxWeight: string) {
      state.filterMaxWeight = filterMaxWeight;
    },
    updateFilterStatuses(state, filterStatuses: string[]) {
      state.filterStatuses = filterStatuses;
    },
    // ------
    //Selection
    handleUpdateBoundingBoxFilter(state, bounds: number[][] | undefined = undefined) {
      state.filterBounds = bounds == undefined ? null : bounds;
    } 
  },
  actions: {
    clearKnelpuntenanalyse ({commit, state}) {
      state.project = null
      state.knelpuntenanalyse = null
      state.selectedTraces = []
      state.traceDetails = {} 
      state.retreivedTraceDetails = []
      // Selection:
      state.filterBounds = null
      // Filter:
      state.filterKnelpuntThemes = []
      state.filterKnelpuntRisks = []
      state.involvedNetbeheerdersOptions = []
      state.involvedThemeOptions = []
      state.filterNetbeheerders = []
      state.filterMinWeight = ""
      state.filterMaxWeight = ""
      state.filterStatuses = []
    },
    fetch ({commit, dispatch, state}, id: string) {
      dispatch("clearKnelpuntenanalyse")
      ProjectsService.project({id: id, xFields: "*"})
        .then((project: Project) => {
          commit("addProject", project);
          commit('addProjectLink', { projectType: "knelpuntenanalyse", id: id, code: project.code } as ProjectLink)
          KnelpuntenanalyseService.knelpuntenanalyse({id: project.detailRef!, xFields: "*"})
            .then((knelpuntenanalyse: Knelpuntenanalyse) => {
              commit("addKnelpuntenanalyse", knelpuntenanalyse);
            })
        })
    },
    handleUpdateSelectedTraces({commit, state}, selectedTraces: string[]) {
      commit("updateSelectedTraces", selectedTraces);

      //If traceDetails are not retreived, retreive it
      selectedTraces.forEach(traceName => {
        if (!(traceName in state.traceDetails)) {
          this.dispatch("fetchTraceDetails", traceName);
        }
      });
    },
    fetchTraceDetails({commit, state}, tracename) {
      const traceDetailRef = state.knelpuntenanalyse.traces.find((x: Trace) => x.name == tracename).detailRef
      TracedetailService.tracedetail({id: traceDetailRef, xFields: "*"})
        .then((tracedetail: TraceDetail) => {
          commit("addKnelpuntDetail", { name: tracename, detail: tracedetail })
        })
        .catch(() => {
          //TODO: Throw error toast and remove item from selected items
        });
    },
    // Filter:
    handleClearFilters({commit}) {
      commit("updateClearFilters")
    },
    handleUpdateFilterKnelpuntThemes({commit, state}, filterKnelpuntThemes: string[]) {
      commit("updateFilterKnelpuntThemes", filterKnelpuntThemes);
    },
    handleUpdateFilterKnelpuntRisks({commit, state}, filterKnelpuntRisks: string[]) {
      commit("updateFilterKnelpuntRisks", filterKnelpuntRisks);
    },
    handleUpdateFilterNetbeheerders({commit, state}, filterNetbeheerders: string[]) {
      commit("updateFilterNetbeheerders", filterNetbeheerders);
    },
    handleUpdateFilterMinWeight({commit, state}, filterMinWeight: number) {
      commit("updateFilterMinWeight", filterMinWeight);
    },
    handleUpdateFilterMaxWeight({commit, state}, filterMaxWeight: number) {
      commit("updateFilterMaxWeight", filterMaxWeight);
    },
    handleUpdateFilterStatuses({commit, state}, filterStatuses: string[]) {
      commit("updateFilterStatuses", filterStatuses);
    },
    // ------
  },
  getters: {
    knelpuntenanalyse(state): Knelpuntenanalyse | null {
      return state.knelpuntenanalyse
    },
    selectedTraces: state => {
      return state.selectedTraces
    },
    traceNames: state => {
      return state.knelpuntenanalyse != null ?  state.knelpuntenanalyse.traces : [];
    },
    // Filter:
    amountOfActiveFilters(state): number {
      let amount = state.filterKnelpuntThemes.length + state.filterKnelpuntRisks.length + state.filterNetbeheerders.length + state.filterStatuses.length;
      if (state.filterMinWeight != "") amount++;
      if (state.filterMaxWeight != "") amount++;
      return amount;
    }, 
    filterKnelpuntThemes: state => {
      return state.filterKnelpuntThemes;
    },
    filterKnelpuntRisks: state => {
      return state.filterKnelpuntRisks;
    },
    involvedNetbeheerdersOptions: state => {
      return [...state.involvedNetbeheerdersOptions, "Overig"];
    },
    involvedThemeOptions: state => {
      return [...state.involvedThemeOptions, "Overig"];
    },
    filterNetbeheerders: state => {
      return state.filterNetbeheerders;
    },
    filterMinWeight: state => {
      return state.filterMinWeight;
    },
    filterMaxWeight: state => {
      return state.filterMaxWeight;
    },
    filterStatuses: state => {
      return state.filterStatuses;
    },
    // ------
    filteredKnelpunten(state, getters): KnelpuntExtended[] {
      // Check if traces are active and trace details retreived
      if (!(Object.keys(state.traceDetails).length > 0 && state.selectedTraces.length > 0)) {
        return [];
      } else {
        // Get knelpunten
        let knelpuntenList: KnelpuntExtended[] = []
        state.selectedTraces.forEach(function(key) {
          const traceDetailRef = state.knelpuntenanalyse.traces.find((x: Trace) => x.name == key).detailRef
          const traceDetail: TraceDetail = state.traceDetails[key];
          if (traceDetail != undefined) {
            traceDetail.knelpunten.forEach((knelpunt: Knelpunt, index: number) => {
              const knelpuntExtended: KnelpuntExtended = { knelpunt: knelpunt, traceDetailRef: traceDetailRef, trace: key, index: index };
              knelpuntenList.push(knelpuntExtended)
            });
          }
        });
        // Check if filter options active
        if (getters.amountOfActiveFilters > 0) {
          // Filter knelpunten on active options
          
          // Type filter option
          if (state.filterKnelpuntThemes.length > 0) {
            knelpuntenList = knelpuntenList.filter((knelpunt: KnelpuntExtended) => {
              if (knelpunt.knelpunt.theme == undefined) {
                return state.filterKnelpuntThemes.includes("overig")
              } else {
                return state.filterKnelpuntThemes.includes(knelpunt.knelpunt.theme)
              }
            });
          }
          // Risk filter option
          if (state.filterKnelpuntRisks.length > 0) {
            knelpuntenList = knelpuntenList.filter((knelpunt: KnelpuntExtended) => {
              if (knelpunt.knelpunt.risks?.length == 0) {
                return state.filterKnelpuntRisks.includes("Overig")
              } else {
                return knelpunt.knelpunt.risks?.some((risk: string) => state.filterKnelpuntRisks.includes(risk));
              }
            });
          }
          // Netbeheerder filter option
          if (state.filterNetbeheerders.length > 0) {
            knelpuntenList = knelpuntenList.filter((knelpunt: KnelpuntExtended) => {
              if (knelpunt.knelpunt.netbeheerder == undefined) {
                return state.filterNetbeheerders.includes("Overig")
              } else {
                return state.filterNetbeheerders.includes(knelpunt.knelpunt.netbeheerder);
              }
            });
          }
          // Min knelpunt weight filter option
          if (state.filterMinWeight != "") {
            const weight = parseInt(state.filterMinWeight);
            knelpuntenList = knelpuntenList.filter((knelpunt: KnelpuntExtended) => {
              return weight <= knelpunt.knelpunt.weight!;
            });
          }
          // Max knelpunt weight filter option
          if (state.filterMaxWeight != "") {
            const weight = parseInt(state.filterMaxWeight);
            knelpuntenList = knelpuntenList.filter((knelpunt: KnelpuntExtended) => {
              return knelpunt.knelpunt.weight! <= weight;
            });
          }
          // Status filter option
          if (state.filterStatuses.length > 0) {
            knelpuntenList = knelpuntenList.filter((knelpunt: KnelpuntExtended) => {
              return state.filterStatuses.includes(knelpunt.knelpunt.status);
            });
          }
        }
        return knelpuntenList;
      }
    },
    filteredSelectedKnelpunten(state, getters): KnelpuntExtended[] {
      // Check If rectangle selection is active
      if (state.filterBounds != null && getters.filteredKnelpunten != undefined && getters.filteredKnelpunten.length > 0) {
        // Filter knelpunten on rectangle selection bounds
        return getters.filteredKnelpunten.filter((knelpuntExtended: KnelpuntExtended) => {
          const knelpunt = knelpuntExtended.knelpunt;
          return state.filterBounds[1][0] <= knelpunt.x! && knelpunt.x! <= state.filterBounds[0][0] && 
            state.filterBounds[1][1] <= knelpunt.y! && knelpunt.y! <= state.filterBounds[0][1];
        })
      // Check if filtered knelpunten is initialized
      } else if (getters.filteredKnelpunten === undefined) {
        return [];
      // Return filteredKnelpunten without further filtering
      } else {
        return getters.filteredKnelpunten;
      }
    }, 
    traceDetails(state): { [tracename: string]: TraceDetail; } {
      if (Object.keys(state.traceDetails).length > 0) {
        const a: { [tracename: string]: TraceDetail; } = {}
        state.selectedTraces.forEach(function(key) {
          const traceDetail: TraceDetail = state.traceDetails[key];
          if (traceDetail != undefined)
            a[key] = traceDetail;
        });
        return a;
      } else {
        return {};
      }
    },
    tracesOverviews(state): Trace[] {
      if (state.knelpuntenanalyse != null) {
        return state.knelpuntenanalyse.traces
      } else {
        return [];
      }
    },
    isTraceSelected(state): boolean {
      return state.selectedTraces.length > 0;
    },
    surroundingTraces(state): SurroundingTrace[] {
      if (state.knelpuntenanalyse != null) {
        return state.knelpuntenanalyse.surroundingTraces;
      } else {
        return [];
      }
    },
    amountOfOpenKnelpunten(state, getters): number {
      return getters.filteredSelectedKnelpunten.filter((knelpuntExtended: KnelpuntExtended) => {
        return knelpuntExtended.knelpunt.status ==  Knelpunt.status.OPEN_NOG_GEEN_ACTIE_ONDERNOMEN;
      }).length;
    },
    amountOfBesprekingKnelpunten(state, getters): number {
      return getters.filteredSelectedKnelpunten.filter((knelpuntExtended: KnelpuntExtended) => {
        return knelpuntExtended.knelpunt.status ==  Knelpunt.status.IN_OPVOLGING_IN_BESPREKING;
      }).length;
    },
    amountOfActieKnelpunten(state, getters): number {
      return getters.filteredSelectedKnelpunten.filter((knelpuntExtended: KnelpuntExtended) => {
        return knelpuntExtended.knelpunt.status ==  Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST;
      }).length;
    },
    projectCode(state): string {
      return state.project != undefined ? state.project.code : ""
    }
  }
}