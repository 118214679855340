


































import Vue, { PropType } from 'vue'
import { KnelpuntdetailService, KnelpuntproblemService, newknelpuntdetailid } from '../../../../../@gen'
import { KnelpuntExtended } from '../../../../../@types/KnelpuntExtended'
import KnelpuntCard from './KnelpuntCard.vue'
import { ProblemOptions } from './KnelpuntOptionValues'

export default Vue.extend({
  components: {
    KnelpuntCard
  },
  props: {
    knelpuntExtended: Object as PropType<KnelpuntExtended>,
    data: {
      type: String,
      default: "",
      required: false
    },
    index: {
      type: Number,
      required: true,
    }
  },
  data () {
    return {
      problem: "",
      startProblem: "",
      creation: false,
      edditing: false,
      problemOptions: ProblemOptions
    }
  },
  created() {
    this.problem = this.data;
    this.startProblem = this.data;
    if (this.data == "") {
      this.creation = true;
      this.edditing = true
    }
  },
  computed: {
    selectedKnelpuntProblem: {
      get: function (): string[] {
        return [this.problem];
      },
      set: function (problems: string[]) {
        if (problems.length != 1) {
          problems = problems.filter((value: string) => value != this.problem)
          this.problem = problems[0];
        }
      }
    },
  },
  methods: {
    async onSave() {
      this.edditing = !this.edditing;
      if (this.knelpuntExtended.knelpunt.detailRef == undefined) {
        //TODO: replace id?
        await KnelpuntdetailService.newknelpuntdetailid({id: "aaaaa",  xFields: "*", payload: {name: this.knelpuntExtended.trace!, index: this.knelpuntExtended.index!}})
          .then((x: newknelpuntdetailid) => {
            this.knelpuntExtended.knelpunt.detailRef = x.id;
          }).catch(x => console.log(x));
      }
      if (this.creation) {
        KnelpuntproblemService.newknelpuntproblem({id: this.knelpuntExtended.knelpunt.detailRef!, payload: { data: this.problem }})
          .then(x => {
            // this.index = x.index;
            //TODO return action to init completed item
          })
        this.$emit('Created', this.problem)
      } else {
        KnelpuntproblemService.updateknelpuntproblem({id: this.knelpuntExtended.knelpunt.detailRef!, payload: { data: this.problem, index: this.index}})
        this.startProblem = this.problem;
      }
    },
    onCancel() {
      if (this.creation) {
        // stop creation
        this.$emit('cancelCreation')
      } else {
        this.problem = this.data
        this.edditing = false;
      }
    },
    onDelete() {
      if (confirm("Ben je zeker?")) {
        if (this.knelpuntExtended.knelpunt.detailRef != undefined) {
          KnelpuntproblemService.deleteknelpuntproblem({id: this.knelpuntExtended.knelpunt.detailRef!, payload: { data: this.problem }})
          this.$emit('deleteProblem');
        }
      }
    }
  }
})
