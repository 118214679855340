








































import Vue from 'vue'
import { NavTabsCard } from '../../../../components'
import { mapGetters } from 'vuex'
import KnelpuntenanalyseTableTraces from './KnelpuntenanalyseTableTraces.vue'
import KnelpuntenanalyseTableNetbeheerers from './KnelpuntenanalyseTableNetbeheerers.vue'
import KnelpuntenanalyseTableThemes from './KnelpuntenanalyseTableThemes.vue'
import KnelpuntenanalyseTableRisks from './KnelpuntenanalyseTableRisks.vue'
import KnelpuntenanalyseTableStatus from './KnelpuntenanalyseTableStatus.vue'

export default Vue.extend({
  components: {
    NavTabsCard,
    KnelpuntenanalyseTableTraces,
    KnelpuntenanalyseTableNetbeheerers,
    KnelpuntenanalyseTableThemes,
    KnelpuntenanalyseTableRisks,
    KnelpuntenanalyseTableStatus
  },
  data () {
    return {
      selectedTab: "tab-traces",
    }
  },
  methods: {
    onTabChange: function(id: string) {
      if (id != undefined) this.selectedTab = id;
    },
  },
  computed: {
    ...mapGetters([
      "isTraceSelected",
    ]),
  },
})
