/**
 * Make theme name correctly readable.
 * @param titleCase Name of theme.
 * @returns Correct readable theme name.
 */
export function titleCaseToSentence(titleCase: string): string {
  let sentence = ""
  if (titleCase != null) {
    titleCase.split('').forEach(letter => {
      if (letter == letter.toUpperCase()) {
        sentence += " ";
      }
      sentence += letter;
    });
  }
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export interface PercentColor {
  pct: number,
  color: DefinedColor
}

export interface DefinedColor {
  r: number,
  g: number,
  b: number
}

const percentColorsDef = [
  { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
  { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
  { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } 
];

/**
 * Maps percentage to a color, source: https://stackoverflow.com/questions/7128675/from-green-to-red-color-depend-on-percentage/7128796
 * @param pct Percentage form 0.0 to 1.0;
 * @param greenHigh Low Red to High Green or High Red to Low Green
 * @param percentColors Color mapping settings.
 * @returns Color string
 */
export function getColorForPercentage(pct, greenHigh = true, percentColors: PercentColor[] = percentColorsDef): string {
  if (!greenHigh) {
    pct = 1.0 - pct;
  }
  
  let i = 0;
  for (i; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
};
