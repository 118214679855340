


































import Vue, { PropType } from 'vue'
import { KnelpuntadviceService, KnelpuntdetailService, newknelpuntdetailid } from '../../../../../@gen'
import { KnelpuntExtended } from '../../../../../@types/KnelpuntExtended'
import KnelpuntCard from './KnelpuntCard.vue'
import { AdviceOptions } from './KnelpuntOptionValues'

export default Vue.extend({
  components: {
    KnelpuntCard
  },
  props: {
    knelpuntExtended: Object as PropType<KnelpuntExtended>,
    data: {
      type: String,
      default: "",
      required: false
    },
    index: {
      type: Number,
      required: true,
    }
  },
  data () {
    return {
      advice: "",
      startAdvice: "",
      creation: false,
      edditing: false,
      adviceOptions: AdviceOptions
    }
  },
  created() {
    this.advice = this.data;
    this.startAdvice = this.data;
    if (this.data == "") {
      this.creation = true;
      this.edditing = true
    }
  },
  computed: {
    selectedKnelpuntAdvice: {
      get: function (): string[] {
        return [this.advice];
      },
      set: function (advice: string[]) {
        if (advice.length != 1) {
          advice = advice.filter((value: string) => value != this.advice)
          this.advice = advice[0];
        }
      }
    },
  },
  methods: {
    async onSave() {
      this.edditing = !this.edditing;
      if (this.knelpuntExtended.knelpunt.detailRef == undefined) {
        //TODO: replace id?
        await KnelpuntdetailService.newknelpuntdetailid({id: "aaaaa",  xFields: "*", payload: {name: this.knelpuntExtended.trace!, index: this.knelpuntExtended.index!}})
          .then((x: newknelpuntdetailid) => {
            this.knelpuntExtended.knelpunt.detailRef = x.id;
          }).catch(x => console.log(x));
      }
      if (this.creation) {
        KnelpuntadviceService.newknelpuntadvice({id: this.knelpuntExtended.knelpunt.detailRef!, payload: { data: this.advice }})
          .then(x => {
            // this.index = x.index;
            //TODO return action to init completed item
          })
        this.$emit('Created', this.advice)
      } else {
        KnelpuntadviceService.updateknelpuntadvice({id: this.knelpuntExtended.knelpunt.detailRef!, payload: { data: this.advice, index: this.index}})
        this.startAdvice = this.advice;
      }
    },
    onCancel() {
      if (this.creation) {
        // stop creation
        this.$emit('cancelCreation')
      } else {
        this.advice = this.data
        this.edditing = false;
      }
    },
    onDelete() {
      if (confirm("Ben je zeker?")) {
        if (this.knelpuntExtended.knelpunt.detailRef != undefined) {
          //KnelpuntadviceService.deleteknelpuntadvice({id: this.knelpuntExtended.knelpunt.detailRef!, payload: { data: this.advice }})
            this.$emit('deleteAdvice');
        }
      }
    }
  }
})
