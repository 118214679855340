/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { deleteknelpuntsolution } from '../models/deleteknelpuntsolution';
import type { newknelpuntsolution } from '../models/newknelpuntsolution';
import type { newknelpuntsolutionindex } from '../models/newknelpuntsolutionindex';
import type { updateknelpuntsolution } from '../models/updateknelpuntsolution';
import { request as __request } from '../core/request';

export class KnelpuntsolutionService {

    /**
     * Delete knelpuntdetail solution
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteknelpuntsolution({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: deleteknelpuntsolution,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/knelpuntsolution/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Update knelpuntdetail solution
     * @returns any Success
     * @throws ApiError
     */
    public static async updateknelpuntsolution({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: updateknelpuntsolution,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/knelpuntsolution/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Put new knelpuntdetail solution
     * @returns newknelpuntsolutionindex Success
     * @throws ApiError
     */
    public static async newknelpuntsolution({
id,
payload,
xFields,
}: {
/** An knelpuntdetail id **/
id: string,
payload: newknelpuntsolution,
/** An optional fields mask **/
xFields?: string,
}): Promise<newknelpuntsolutionindex> {
        const result = await __request({
            method: 'PUT',
            path: `/knelpuntsolution/${id}`,
            headers: {
                'X-Fields': xFields,
            },
            body: payload,
        });
        return result.body;
    }

}