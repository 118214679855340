import { Project } from "@/@gen"
import { Module } from "vuex";

export interface ProjectLink {
  projectType: string,
  id: string,
  code: string
}

const MAX_SIDEBAR_PROJECT_LINKS = 4;

export const ProjectModule: Module<any, any> = {
  state: () => ({
    projects: [] as ProjectLink[]
  }),
  mutations: {
    addProjectLink (state, projectLink: ProjectLink) {
      // check if item is already included, so that it can be placed to front.
      const currentProjectIndex = state.projects.findIndex((project: ProjectLink) => project.id == projectLink.id)
      if (currentProjectIndex != -1) {
        state.projects.splice(currentProjectIndex, 1)
      // if not already included, check if maximum is reached, else remove last item.
      } else if (state.projects.length == MAX_SIDEBAR_PROJECT_LINKS) {
        state.projects.pop();
      }
      // add project to front of list
      state.projects.unshift(projectLink)
    }
  },
  actions: {},
  getters: {
    projects(state): ProjectLink[] {
      return state.projects;
    },
  }
}