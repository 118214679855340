

























import Vue, { PropType } from 'vue'
import { SurroundingTrace } from '../../../../../@gen'
import { titleCaseToSentence } from '../../Helper'

export default Vue.extend({
  props: {
    surroundingTrace: {
        type: Object as PropType<SurroundingTrace>,
        required: true
    }
  },
  methods: {
    titleCaseToSentence: titleCaseToSentence,
  }
})
