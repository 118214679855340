/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Knelpunt = {
    /**
     * ID to knelpuntdetail
     */
    detailRef?: string;
    /**
     * Netbeheerder of pipelines or cables that takes part in this knelpunt
     */
    netbeheerder?: string;
    /**
     * List of risk the knelpunt has
     */
    risks?: Array<string>;
    /**
     * What the status of this knelpunt is
     */
    status?: Knelpunt.status;
    /**
     * Index of surroundingTrace that makes this knelpunt
     */
    surroundingTraceRef?: number;
    /**
     * What kind of knelpunt this is
     */
    theme?: string;
    /**
     * Knelpunt weight
     */
    weight?: number;
    /**
     * X coordinate of knelpunt
     */
    'x'?: number;
    /**
     * Y coordinate of knelpunt
     */
    'y'?: number;
}

export namespace Knelpunt {

    /**
     * What the status of this knelpunt is
     */
    export enum status {
        OPEN_NOG_GEEN_ACTIE_ONDERNOMEN = 'Open - Nog geen actie ondernomen',
        IN_OPVOLGING_IN_BESPREKING = 'In opvolging - In bespreking',
        IN_OPVOLGING_ACTIE_VEREIST = 'In opvolging - Actie vereist',
        AFGEHANDELD_GEEN_BEZWAAR = 'Afgehandeld - Geen bezwaar',
        AFGEHANDELD_OPLOSSING_AFGESTEMD = 'Afgehandeld - Oplossing afgestemd',
    }


}