// TODO: Needs better playing and name
import { Project } from "../../@gen/index"

/** 
 * Colors for projects state in '/projects' 
 * @param projectType string corresponding to value in `Project.projectType` enum.
*/
export function projectTypeColors(projectType: string): string {
  switch(projectType) {
    case Project.projectType.KNELPUNTENANALYSE:
      return "rgb(252, 132, 3)"
    case Project.projectType.FAALKANSBEPALING:
      return "rgb(252, 53, 3)"
    default:
      return "gray"
  }
}

/** 
 * Colors for projects state in '/projects' 
 * @param projectState string corresponding to value in `Project.projectState` enum.
*/
export function projectStateColors(projectState: string): string {
  switch(projectState) {
    case Project.projectState.OPEN:
      return "rgb(51, 204, 0)"
    case Project.projectState.CLOSED:
      return "rgb(255, 102, 102)"
    default:
      return "gray"
  }
}