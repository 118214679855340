



















import Vue from "vue";
import LoginCard from "../components/Cards/LoginCard.vue";

export enum LoginErrorTypes {
  Unauthorized = "Unauthorized",
  VerifyEmail = "VerifyEmail"
}

export default Vue.extend({
  components: {
    LoginCard,
  },
  data() {
    return {
      error: "" as string,
      errorType: "" as LoginErrorTypes,
      errorTitle: "" as string,
      errorDescription: "" as string
    }
  },
  created() {
    this.error = this.$route.query.error as string;
    if (this.error != undefined) {
      const errors = (this.$route.query.error_description as string).split('|')
      this.errorType = LoginErrorTypes[errors[0]];
      this.errorTitle = errors[1];
      this.errorDescription = errors[2];
    }
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
});
