/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Project } from '../models/Project';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * Get list of all projects
     * @returns Project Success
     * @throws ApiError
     */
    public static async listProjects({
xFields,
}: {
/** An optional fields mask **/
xFields?: string,
}): Promise<Array<Project>> {
        const result = await __request({
            method: 'GET',
            path: `/projects/`,
            headers: {
                'X-Fields': xFields,
            },
        });
        return result.body;
    }

    /**
     * Get project by id
     * @returns Project Success
     * @throws ApiError
     */
    public static async project({
id,
xFields,
}: {
/** An project id **/
id: string,
/** An optional fields mask **/
xFields?: string,
}): Promise<Project> {
        const result = await __request({
            method: 'GET',
            path: `/projects/${id}`,
            headers: {
                'X-Fields': xFields,
            },
        });
        return result.body;
    }

}