






















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { KnelpuntExtended } from '../../../../@types/KnelpuntExtended';

export default Vue.extend({
  data() {
    return {
      currentSort: "index",
      currentSortOrder: "asc",
      keys: [] as string[],
    }
  },
  computed: {
    ...mapGetters([
      "filteredSelectedKnelpunten",
      "filterNetbeheerders",
      "involvedNetbeheerdersOptions",
      "selectedTraces",
    ]),
    amountOfKnelpuntenPerNetbeheer: {
      get(): { index: number; name: string; values: any;}[]  {
        const map: Map<string, Map<string, number>> = new Map();
        //Map setup:
        ["Totaal", ...this.involvedNetbeheerdersOptions].forEach((netbeheerder: string) => {
          const row = new Map<string, number>()
          this.keys = ["Totaal", ...(this.selectedTraces as string[])]
          //Add row items with initial value of 0
          this.keys.forEach((trace: string) => {
            row.set(trace, 0);
          });
          map[netbeheerder] = row;       
        });
        //Filling map with row data
        this.filteredSelectedKnelpunten.forEach((knelpuntExtended: KnelpuntExtended) => {
          //Add up total amount of knelpunten
          map["Totaal"]!.set("Totaal", map["Totaal"]!.get("Totaal") + 1);
          map["Totaal"]!.set(knelpuntExtended.trace, map["Totaal"]!.get(knelpuntExtended.trace) + 1);
          const currentNetbeheerder = knelpuntExtended.knelpunt.netbeheerder;
          if (currentNetbeheerder == undefined) {
            //Add up remaining knelpunten without a type
            map["Overig"]!.set("Totaal", map["Overig"]!.get("Totaal") + 1);
            map["Overig"]!.set(knelpuntExtended.trace, map["Overig"]!.get(knelpuntExtended.trace) + 1);
          } else {
            if (map[currentNetbeheerder] != undefined) {
              //Add up knelpunten with same type  
              map[currentNetbeheerder]!.set("Totaal", map[currentNetbeheerder]!.get("Totaal") + 1);
              map[currentNetbeheerder]!.set(knelpuntExtended.trace, map[currentNetbeheerder]!.get(knelpuntExtended.trace) + 1);
            } else {
              throw "error: not added netbeheerder introduced";
            }
          }
        });
        //Return items
        return Object.keys(map).map((key: string, index: number) => {
          return {
            index: index + 1,
            name: key,
            values: map[key]
          }
        })
      },
      set(value: string[]): void {
        return;
      }
    },
    filterNetbeheerdersItems: {
      get: function(): string[]  {
        return this.filterNetbeheerders;
      },
      set: function(filterNetbeheerders: string[] | ""): void {
        this.$store.dispatch("handleUpdateFilterNetbeheerders", filterNetbeheerders);
      }
    },
  },
})
