




















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Knelpunt } from '../../../../@gen';
import { KnelpuntExtended } from '../../../../@types/KnelpuntExtended';
import { KnelpuntWeightSettingsOptions } from '../KnelpuntWeightSettings';

export default Vue.extend({
  data() {
    return {
      filterKnelpuntRisksOptions: KnelpuntWeightSettingsOptions,
      currentSort: "index",
      currentSortOrder: "asc",
      keys: [] as string[],
    }
  },
  computed: {
    ...mapGetters([
      "filteredSelectedKnelpunten",
      "filterKnelpuntRisks",
      "selectedTraces",
    ]),
    amountOfKnelpuntenPerRisk: {
      get(): { index: number; name: string; values: any;}[] {
        const map: Map<string, Map<string, number>> = new Map();
        //Map setup:
        ["Totaal", ...this.filterKnelpuntRisksOptions].forEach((risk: string) => {
          const row = new Map<string, number>()
          this.keys = ["Totaal", ...(this.selectedTraces as string[])]
          //Add row items with initial value of 0
          this.keys.forEach((trace: string) => {
            row.set(trace, 0);
          });
          map[risk] = row;       
        });
        //Filling map with row data
        this.filteredSelectedKnelpunten.forEach((knelpunt: KnelpuntExtended) => {
          //Add up total amount of knelpunten
          map["Totaal"].set("Totaal", map["Totaal"]!.get("Totaal") + 1);
          map["Totaal"].set(knelpunt.trace, map["Totaal"]!.get(knelpunt.trace) + 1);
          if (knelpunt.knelpunt.risks!.length == 0) {
            //Add up remaining knelpunten without a type  
            map["Overig"].set("Totaal", map["Overig"]!.get("Totaal") + 1);
            map["Overig"].set(knelpunt.trace, map["Overig"]!.get(knelpunt.trace) + 1);
          } else {
            //Add up knelpunten with same type
            knelpunt.knelpunt.risks!.forEach((risk: string) => {
              if (map[risk] != undefined) {
                map[risk].set("Totaal", map[risk]!.get("Totaal") + 1);
                map[risk].set(knelpunt.trace, map[risk]!.get(knelpunt.trace) + 1); 
              } else {
                throw "error: unsupported risk introduced";
              }
            });
          }
        });
        //Return items
        return Object.keys(map).map((key: string, index: number) => {
          return {
            index: index + 1,
            name: key,
            values: map[key]
          }
        })
      },
      set(value: string[]): void {
        return;
      }
    },
    filterKnelpuntRisksItems: {
      get: function(): string[] {
        return this.filterKnelpuntRisks;
      },
      set: function(filterKnelpuntRisks: string[] | ""): void {
        this.$store.dispatch("handleUpdateFilterKnelpuntRisks", filterKnelpuntRisks);
      }
    },
  }
})
