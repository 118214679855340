































































































import Vue, { VueConstructor } from 'vue'
import L, { LatLngBoundsExpression, Layer, Rectangle } from "leaflet"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Knelpunt, Knelpuntenanalyse, KnelpuntWeightRule, SurroundingTrace, TraceDetail } from '../../../../@gen';
import { KnelpuntExtended } from '../../../../@types/KnelpuntExtended'
import { titleCaseToSentence, getColorForPercentage } from '../Helper'
import { KnelpuntPoint } from '../KnelpuntPoint'
import { TraceLine } from '../TraceLine'
import { SurroundingTraceLine } from '../SurroundingTraceLine'
import { GeoStandard, GeoStandards } from '../GeoStandard'
import KnelpuntPopup from './@components/KnelpuntPopup.vue'
import SurroundingTracePopup from './@components/SurroundingTracePopup.vue'

import { LMap, LTileLayer, LControlScale, LPolyline, LCircleMarker, LPopup, LRectangle, LTooltip } from 'vue2-leaflet';

export default (Vue as VueConstructor<
  Vue & {
  $refs: {
    mapBox: InstanceType<typeof LMap>;
  };
}
>).extend({
  components: {
    LMap,
    LTileLayer,
    LControlScale,
    LPolyline,
    LCircleMarker,
    LPopup,
    LRectangle,
    LTooltip,
    KnelpuntPopup,
    SurroundingTracePopup,
  },
  data () {
    return {
      showKnelpunten: true,
      showSurroundingTraces: true,
      showThemeKnelpunt: false,
      fullscreen: false,

      maxWeight: 0,
     
      GeoStandards: GeoStandards,
      
      traceLines: [] as TraceLine[],
      knelpuntPoints: [] as KnelpuntPoint[],
      selectionRectangle: null as LatLngBoundsExpression | null,

      //TODO: Increace performance adding and removing traces and knelpunten from the map.
      surroundingTraceLines: [] as { [index: number]: SurroundingTraceLine },
      activeSurroundingTraces: [] as number[],
      activeSurroundingTraceLines: [] as SurroundingTraceLine[],

      //tile-layer
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }
  },
  computed: {
    ...mapGetters([
      "filteredKnelpunten",
      "traceDetails",
      "surroundingTraces",
      "knelpuntenanalyse"
    ]),
  },
  mounted() {
    this.resetMapView()
    this.handleUpdateBoundingBoxFilter()
  },
  methods: {
    ...mapMutations([
      "handleUpdateBoundingBoxFilter"
    ]),
    viewKnelpuntInInfo(knelpunt: KnelpuntExtended) {
      this.$emit("viewKnelpunt", knelpunt)
    },
    viewKnelpunt(knelpunt: KnelpuntExtended) {
      const knelpuntCoordinates: [number, number] = [knelpunt.knelpunt.x!, knelpunt.knelpunt.y!];
      const zoomOutValue = 0.0002;
      this.$refs.mapBox.fitBounds([[knelpunt.knelpunt.x! + zoomOutValue, knelpunt.knelpunt.y! + zoomOutValue], [knelpunt.knelpunt.x! - zoomOutValue, knelpunt.knelpunt.y! - zoomOutValue]]);
      const highlightedKnelpunt = this.knelpuntPoints.find((knelpuntPoint: KnelpuntPoint) => {
        return knelpuntPoint.knelpunt === knelpunt
      })
      if (highlightedKnelpunt != undefined) {
        const originalColor = highlightedKnelpunt.color;
        const originalRadius = highlightedKnelpunt.radius
        highlightedKnelpunt.color = "blue"
        highlightedKnelpunt.radius = 20
        setTimeout(() => { 
          if (highlightedKnelpunt != undefined) {
            highlightedKnelpunt.color = originalColor;
            highlightedKnelpunt.radius = originalRadius
          }
        }, 2500);
      }
    },
    //mouse events
    knelpuntMouseover(e, knelpuntPoint: KnelpuntPoint) {
      const layer: L.CircleMarker = e.target;
      layer.bringToFront();
      knelpuntPoint.color = 'blue';
      knelpuntPoint.weight = 3;
    },
    knelpuntMouseout(e, knelpuntPoint: KnelpuntPoint) {
      const layer: L.CircleMarker = e.target;
      layer.bringToBack();
      knelpuntPoint.weight = 2
      this.updateKnelpuntColor(knelpuntPoint)
    },
    traceMouseover(e, traceLine: TraceLine) {
      const layer: L.Polyline = e.target;
      layer.bringToFront();
      traceLine.color = 'blue';
      traceLine.weight = 4;
      traceLine.mouseLatLng = [e.latlng.lat, e.latlng.lng];
    },
    traceMouseout(e, traceLine: TraceLine) {
      const layer: L.Polyline = e.target;
      layer.bringToBack();
      traceLine.color = traceLine.traceLineColor;
      traceLine.weight = 2;
    },
    surroundingTraceMouseover(e, surroundingTraceLine: SurroundingTraceLine) {
      const layer: L.Polyline = e.target;
      layer.bringToFront();
      surroundingTraceLine.color = 'blue';
      surroundingTraceLine.weight = 2;
    },
    surroundingTraceMouseout(e, surroundingTraceLine: SurroundingTraceLine) {
      const layer: L.Polyline = e.target;
      layer.bringToBack();
      surroundingTraceLine.color = surroundingTraceLine.surroundingTraceLineColor;
      surroundingTraceLine.weight = 1;
    },
    //
    handleBoxZoom(e) {
      const bounds = (e as any).boxZoomBounds;
      const boundBox: LatLngBoundsExpression = [[bounds._northEast.lat, bounds._northEast.lng],[bounds._southWest.lat, bounds._southWest.lng]];
      this.selectionRectangle = boundBox;
      this.handleUpdateBoundingBoxFilter(boundBox);
      this.setSelectedKnelpunten(boundBox);
    },
    resetMapView() {
      if (this.knelpuntenanalyse != null) {
        this.$refs.mapBox.fitBounds(this.knelpuntenanalyse.viewBounds);
      }
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      setTimeout(() => { this.$refs.mapBox.mapObject.invalidateSize()}, 5);
    },
    stopSelection() {
      this.selectionRectangle = null;
      this.handleUpdateBoundingBoxFilter();
      this.knelpuntPoints.forEach((knelpuntPoint: KnelpuntPoint) => {
        if (!knelpuntPoint.knelpuntSelected) {
          knelpuntPoint.knelpuntSelected = true;
          this.updateKnelpuntColor(knelpuntPoint);
        }
      });
    },
    setSelectedKnelpunten(selection: LatLngBoundsExpression) {
      this.knelpuntPoints.forEach((knelpuntPoint: KnelpuntPoint) => {
        //Check if knelpunt is inside the selection
        const knelpunt: Knelpunt = knelpuntPoint.knelpunt.knelpunt; 
        const inSelection: boolean = selection[1][0] <= knelpunt.x! && knelpunt.x! <= selection[0][0] && 
          selection[1][1] <= knelpunt.y! && knelpunt.y! <= selection[0][1];
        knelpuntPoint.knelpuntSelected = inSelection;
        this.updateKnelpuntColor(knelpuntPoint)
      });
    },
    updateKnelpuntenColors() {
      this.knelpuntPoints.forEach((knelpuntPoint: KnelpuntPoint) => {
        this.updateKnelpuntColor(knelpuntPoint)
      });
    },
    updateKnelpuntColor(knelpuntPoint: KnelpuntPoint) {
      //Knelpunt color if not selected
      if (!knelpuntPoint.knelpuntSelected) {
        knelpuntPoint.color = "rgba(179, 179, 179, 0.8)";
      //Knelpunt color based on knelpunt theme if theme is active
      } else if (this.showThemeKnelpunt) {
        knelpuntPoint.color = knelpuntPoint.knelpuntThemeColor;
        knelpuntPoint.radius = 4;
      //Knelpunt color based on knelpunt weight if weight is active
      } else {
        knelpuntPoint.color = knelpuntPoint.knelpuntWeightColor;
        knelpuntPoint.radius = 3 + 7 * knelpuntPoint.knelpuntWeightPercentage;
      }
    },
    titleCaseToSentence: titleCaseToSentence,
    getColorForPercentage: getColorForPercentage,
  },
  watch: {
    knelpuntenanalyse(knelpuntenanalyse: Knelpuntenanalyse | null) {
      //Set initial viewbouds of map when loading in a knelpuntenanalyse project
      this.resetMapView()
       
      this.knelpuntenanalyse.weightConfig.forEach((rule: KnelpuntWeightRule) => {
        if (rule.weight! > this.maxWeight) 
          this.maxWeight = rule.weight!;
      });
    },
    showThemeKnelpunt(themeKnelpunt: boolean) {
      this.updateKnelpuntenColors()
    },
    activeSurroundingTraces() {
      this.activeSurroundingTraceLines = []
      this.activeSurroundingTraces.forEach((activeSurroundingTrace: number) => {
        if (activeSurroundingTrace in this.surroundingTraceLines)
          this.activeSurroundingTraceLines.push(this.surroundingTraceLines[activeSurroundingTrace]);
      });
    },   
    surroundingTraces(surroundingTraces: SurroundingTrace[]) {
      const surroundingTraceLines: { [index: number]: SurroundingTraceLine } = {};

      surroundingTraces.forEach((surroundingTrace: SurroundingTrace) => {
        const surroundingTraceThemeColor = (surroundingTrace.theme! in GeoStandards ? GeoStandards[surroundingTrace.theme!] : GeoStandards["default"]).color;

        const surroundingTraceLine: SurroundingTraceLine = {
          surroundingTrace: surroundingTrace,
          surroundingTraceLineColor: surroundingTraceThemeColor,
          color: surroundingTraceThemeColor,
          weight: 1,
        }

        //Add to list
        surroundingTraceLines[surroundingTrace.index!] = surroundingTraceLine;
      });
      this.surroundingTraceLines = surroundingTraceLines;
    },
    
    filteredKnelpunten(filteredKnelpunten) {
      const activeSurroundingTraces: number[] = []
      
      const knelpuntColorDict: { [percentage: number]: string } = { };

      //TODO remove only missing knelpunten
      this.knelpuntPoints = [];

      //Add new knelpunten
      filteredKnelpunten.forEach((knelpuntExtended: KnelpuntExtended) => {
        const knelpunt: Knelpunt = knelpuntExtended.knelpunt;
        
        //Calculate and add knelpunt weight color
        let knelpuntWeightColor;
        const knelpuntWeightPercentage = knelpunt.weight! / this.maxWeight;
        //Is the weight color already calculated?
        if (knelpuntWeightPercentage in knelpuntColorDict) {
          knelpuntWeightColor = knelpuntColorDict[knelpuntWeightPercentage];
        //Calculate knelpunt weight based on color
        } else {
          knelpuntWeightColor = getColorForPercentage(knelpuntWeightPercentage, false);
          knelpuntColorDict[knelpuntWeightPercentage] = knelpuntWeightColor;
        }

        //knelpunt theme color
        const knelpuntThemeColor = (knelpunt.theme! in GeoStandards ? GeoStandards[knelpunt.theme!] : GeoStandards["default"]).color

        //knelpunt settings and init
        const knelpuntPoint: KnelpuntPoint = { 
          knelpunt: knelpuntExtended,
          knelpuntThemeColor: knelpuntThemeColor,
          knelpuntWeightColor: knelpuntWeightColor,
          knelpuntWeightPercentage: knelpuntWeightPercentage,
          knelpuntSelected: true,
          radius: this.showThemeKnelpunt ? 4 : 3 + 7 * knelpuntWeightPercentage,
          weight: 2, 
          color: this.showThemeKnelpunt ? knelpuntThemeColor : knelpuntWeightColor
        }

        this.knelpuntPoints.push(knelpuntPoint)

        //Check if surrounding trace can be found in a active knelpunt and should be added to active list.
        if (knelpunt.surroundingTraceRef != undefined && activeSurroundingTraces.indexOf(knelpunt.surroundingTraceRef!) == -1) {
          activeSurroundingTraces.push(knelpunt.surroundingTraceRef!)
        }
      });

      this.activeSurroundingTraces = activeSurroundingTraces;

      //Initial selected knelpunten coloring
      if (this.selectionRectangle != null) {
        this.setSelectedKnelpunten(this.selectionRectangle);
      }
    },
    /**
     * Adds traces to map.
     */
    traceDetails(newTraceDetails: { [tracename: string]: TraceDetail; }) {
      this.traceLines = []

      const traceLineColor = 'rgba(125,139,143,1.0)';
      //Add new traces to the map
      Object.keys(newTraceDetails).forEach((traceName: string) => {
        const traceDetail: TraceDetail = newTraceDetails[traceName]

        const traceLine: TraceLine = {
          trace: traceDetail.trace as L.LatLngExpression[][],
          traceName: traceName,
          traceLineColor: traceLineColor,
          color: traceLineColor,
          weight: 2,
          mouseLatLng: [0,0] as L.LatLngExpression,
        }

        this.traceLines.push(traceLine);
      });
    }
  }
})
