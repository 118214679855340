// =========================================================
// * Vue Material Dashboard PRO - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import store from "./store";

Vue.config.productionTip = false;

import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";

// Plugins
import Chartist from "chartist";

// Router setup
// import routes from "./routes/tim-routes";
import routes from "./routes/routes";

// Plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);

// Configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active"
});

// Auth0
// Import the Auth0 configuration
// import { domain, clientId } from "./auth/auth-config.json";
const domain: string = process.env.VUE_APP_AUTH_DOMAIN;
const clientId: string = process.env.VUE_APP_AUTH_CLIENTID;
const audience: string = process.env.VUE_APP_AUTH_AUDIENCE;

// Import the plugin here
import { Auth0Plugin } from "./auth";
import { OpenAPI } from "./@gen";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    window.history.pushState({},"", "/")
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// Import API base path
OpenAPI.BASE = process.env.VUE_APP_API_BASE + "/api";

Vue.config.productionTip = false;

// Global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router,
  store
});
