









































import Vue, { PropType } from 'vue'
import { titleCaseToSentence } from '../../Helper'
import { KnelpuntPoint } from '../../KnelpuntPoint'

export default Vue.extend({
  props: {
    knelpuntPoint: {
      type: Object as PropType<KnelpuntPoint>,
      required: true
    }
  },
  methods: {
    titleCaseToSentence: titleCaseToSentence,
    viewKnelpuntInInfo() {
      this.$emit("viewKnelpunt", this.knelpuntPoint.knelpunt)
    }
  }
})
