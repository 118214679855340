/**
 * @param name Name of risk that determines the knelpunt weight.
 * @param value Value of knelpunt weight corresponding to the risk.
 */
export interface KnelpuntWeightSetting {
  name: string;
  value: number;
}

/**
 * @returns List of knelpunt weight settings corresponding to a knelpunt risks.
 */
export const KnelpuntWeightSettings: KnelpuntWeightSetting[] = [
  {
    name: "Stadswegen",
    value: 50000,
  },
  {
    name: "A- of N-wegen",
    value: 100000,
  },
  {
    name: "Waterkeringen",
    value: 100000,
  },
  {
    name: "Waterlijnen",
    value: 15000,
  },
  {
    name: "Watervlakken",
    value: 100000,
  },
  {
    name: "Kabels en Leidingen",
    value: 5000,
  },
  {
    name: "Eis Voorzorgsmaatregel",
    value: 50000,
  },
];

/** @returns List of supported knelpunt risks that corresponds to a knelpunt weight. */
export const KnelpuntWeightSettingsOptions: string[] = [...KnelpuntWeightSettings.map((x: KnelpuntWeightSetting) => x.name), "Overig"];