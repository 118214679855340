










































































































































import Vue from 'vue'
import ToolbarHeader from "./@layout/ToolbarHeader.vue"
import { Pagination, Badge } from "../../components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { Project, ProjectsService } from "../../@gen/index"
import DashboardFooter from "./@layout/DashboardFooter.vue"
import { projectStateColors, projectTypeColors } from "./TypeValues"
import { OpenAPI } from "../../@gen/core/OpenAPI"

export default Vue.extend({
  components: {
    ToolbarHeader,
    Badge,
    Pagination,
    DashboardFooter,
  },
  async created () {
    OpenAPI.TOKEN = await this.$auth.getTokenSilently();
    ProjectsService.listProjects({ xFields: "*" })
      .then((data: Project[]) => {
        this.tableData = data;
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["name", "code"],
          threshold: 0.3
        });
      })
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData(): Project[] {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      //TODO: Not efficent that this is done before search filtering and should be handeld on server
      if (this.selectedProjectTypes.length > 0) {
        console.log(result.filter((x: Project) => this.selectedProjectTypes.includes(x.projectType)))
        result = result.filter((x: Project) => this.selectedProjectTypes.includes(x.projectType))
      }
      return result.slice(this.from, this.to);
    },
    to(): number {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from(): number {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total(): number {
      //TODO: Does not take into account the filtered projectTypes
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      projectTypes: Object.values(Project.projectType) as string[],
      selectedProjectTypes: [] as Project.projectType[],
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      footerTable: ["Code", "Naam", "Project type", "Beschrijving", "Datum", "Open project", "Status"],
      searchQuery: "",
      propsToSearch: ["name", "code",],
      tableData: [] as Project[],
      searchedData: [] as Project[],
      fuseSearch: {} as Fuse<Project>,
    };
  },
  methods: {
    projectStateColors,
    projectTypeColors,
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    dateString(date: string): string {
      return new Date(date).toLocaleDateString()
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse<Project>(this.tableData, {
      keys: ["name", "code"],
      threshold: 0.3
    });
  },
  watch: {
    //TODO: Server side filtering in API
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result: Project[] = this.tableData;
      if (value !== "") {
        const searchResult = this.fuseSearch.search(this.searchQuery);
        result = searchResult.map((x: any) => { return x.item }) as Project[];
      }
      this.searchedData = result;
    }
  }
})
