

































import Vue from 'vue'

export default Vue.extend({
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    }
  }
})
