/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Project = {
    /**
     * The project identifier
     */
    code: string;
    /**
     * The project date of opening
     */
    date: string;
    /**
     * The project description
     */
    description: string;
    /**
     * ID to project details
     */
    detailRef?: string;
    /**
     * The project id
     */
    id: string;
    /**
     * The project name
     */
    name: string;
    /**
     * The project state
     */
    projectState: Project.projectState;
    /**
     * The project type
     */
    projectType: Project.projectType;
}

export namespace Project {

    /**
     * The project state
     */
    export enum projectState {
        OPEN = 'Open',
        CLOSED = 'Closed',
    }

    /**
     * The project type
     */
    export enum projectType {
        KNELPUNTENANALYSE = 'Knelpuntenanalyse',
        FAALKANSBEPALING = 'Faalkansbepaling',
        EFFECTBEPALING = 'Effectbepaling',
    }


}