import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { ProjectModule } from "./project";
import { KnelpuntenanalyseModule } from "./knelpuntenanalyse"

export default new Vuex.Store({
  modules: {
    ProjectModule,
    KnelpuntenanalyseModule,
  },
  state: {},
  mutations: {},
  actions: {},
});
