






















import Vue, { VueConstructor } from 'vue'
import KnelpuntenanalyseToolbarHeader from "./@components/KnelpuntenanalyseToolbarHeader.vue"
import KnelpuntenanalyseInformationBox from "./@components/KnelpuntenanalyseInformationBox.vue"
import KnelpuntenanalyseTableBox from "./@components/KnelpuntenanalyseTableBox.vue"
import KnelpuntenanalyseMapBox from "./@components/KnelpuntenanalyseMapBox.vue"
import { OpenAPI } from "../../../@gen/core/OpenAPI"
import { ProjectLink } from "../../../store/project"
import { mapGetters } from 'vuex'
import { KnelpuntExtended } from '../../../@types/KnelpuntExtended'


export default (Vue as VueConstructor<
  Vue & {
  $refs: {
    info: InstanceType<typeof KnelpuntenanalyseInformationBox>;
    map: InstanceType<typeof KnelpuntenanalyseMapBox>;
  };
}
>).extend({
  components: { 
    KnelpuntenanalyseToolbarHeader,
    KnelpuntenanalyseInformationBox,
    KnelpuntenanalyseTableBox,
    KnelpuntenanalyseMapBox,
  },
  async created() {
    OpenAPI.TOKEN = await this.$auth.getTokenSilently();
    this.$store.dispatch("fetch", this.$route.params.projectID)
  },
  watch: {
    '$route.params.projectID' (id) {
      this.$store.dispatch("fetch", id);
    }
  },
  computed: {
    ...mapGetters([
      "projectCode"
    ])
  }, 
  methods: {
    viewKnelpuntInMap(knelpunt: KnelpuntExtended) {
      this.$refs.map.viewKnelpunt(knelpunt);
    },
    viewKnelpuntInInfo(knelpunt: KnelpuntExtended) {
      this.$refs.info.viewKnelpunt(knelpunt);
    }
  }
})
