/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Knelpuntenanalyse } from '../models/Knelpuntenanalyse';
import { request as __request } from '../core/request';

export class KnelpuntenanalyseService {

    /**
     * Get knelpuntenanalyse by id
     * @returns Knelpuntenanalyse Success
     * @throws ApiError
     */
    public static async knelpuntenanalyse({
id,
xFields,
}: {
/** An knelpuntenanalyse project id **/
id: string,
/** An optional fields mask **/
xFields?: string,
}): Promise<Knelpuntenanalyse> {
        const result = await __request({
            method: 'GET',
            path: `/knelpuntenanalyse/${id}`,
            headers: {
                'X-Fields': xFields,
            },
        });
        return result.body;
    }

}