export const ProblemOptions = [
  "Kabel/leiding in parallel ligging nabij",
  "Kruisende kabel of leiding",
  "Weinig/slechte gegevens",
  "Obstakel"
];

export const AdviceOptions = [
  "Netbeheerder contacteren",
  "In veld datacontrole",
  "Verlegging"
];

export const SolutionOptions = [
  "Zandbaan/stelconplaten",
  "Vrijdragende constructie",
  "Verlegging"
];