













































import Vue from "vue";
import { CollapseTransition } from "vue2-transitions";

export default Vue.extend({
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: "Gebruikersnaam1"
    },
    avatar: {
      type: String,
      default: "./img/default-avatar.png"
    }
  },
  data() {
    return {
      isClosed: true
    };
  },
  computed: {
    username(): string {
      if (!this.$auth.loading && this.$auth.isAuthenticated) {
        return this.$auth.user.name;
      } else if (this.$auth.loading) {
        return "Loading...";
      } else {
        return "Niet ingelogd"
      }
    },
    userPicture(): string {
      if (!this.$auth.loading && this.$auth.isAuthenticated) {
        return this.$auth.user.picture;
      }  else {
        return "./img/default-avatar.png";
      }
    },
  },
  methods: {
    clicked(e) {
      e.preventDefault();
    },
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
});
