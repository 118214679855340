











































/* eslint-disable no-new */
//import { Component } from "@fullcalendar/vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  const docClasses = document.body.classList;
  const isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import Vue from "vue";
import { ZoomCenterTransition } from "vue2-transitions";
import SidebarNavigation from "./SidebarNavigation.vue"
// import UserMenu from "../../../pages/Dashboard/Layout/Extra/UserMenu.vue";
import DashboardUserMenu from "./DashboardUserMenu.vue";
// import ContentFooter from "../../../pages/Dashboard/Layout/ContentFooter.vue";

declare const require: any

export default Vue.extend({
  components: {
    ZoomCenterTransition,
    SidebarNavigation,
    // UserMenu,
    DashboardUserMenu,
    // ContentFooter
  },
  data() {
    return {
      sidebarBackgroundColor: "black", 
      sidebarBackground: "purple",
      sidebarBackgroundImage: "", //./img/sidebar-2.jpg
      sidebarMini: true,
      sidebarImg: true,
      logo: ""
    };
  },
  created() {
    // TODO: make dynamic images work
    // this.logo = require("@/assets/logo.png")
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
       }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    }
  }
})
