/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type knelpuntstatusupdate = {
    /**
     * Knelpunt index from list in trace
     */
    index: number;
    /**
     * Trace name
     */
    name: string;
    /**
     * New knelpunt status
     */
    status: knelpuntstatusupdate.status;
}

export namespace knelpuntstatusupdate {

    /**
     * New knelpunt status
     */
    export enum status {
        OPEN_NOG_GEEN_ACTIE_ONDERNOMEN = 'Open - Nog geen actie ondernomen',
        IN_OPVOLGING_IN_BESPREKING = 'In opvolging - In bespreking',
        IN_OPVOLGING_ACTIE_VEREIST = 'In opvolging - Actie vereist',
        AFGEHANDELD_GEEN_BEZWAAR = 'Afgehandeld - Geen bezwaar',
        AFGEHANDELD_OPLOSSING_AFGESTEMD = 'Afgehandeld - Oplossing afgestemd',
    }


}