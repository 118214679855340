/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { deleteknelpuntadvice } from '../models/deleteknelpuntadvice';
import type { newknelpuntadvice } from '../models/newknelpuntadvice';
import type { newknelpuntadviceindex } from '../models/newknelpuntadviceindex';
import type { updateknelpuntadvice } from '../models/updateknelpuntadvice';
import { request as __request } from '../core/request';

export class KnelpuntadviceService {

    /**
     * Delete knelpuntdetail advice
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteknelpuntadvice({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: deleteknelpuntadvice,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/knelpuntadvice/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Update knelpuntdetail advice
     * @returns any Success
     * @throws ApiError
     */
    public static async updateknelpuntadvice({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: updateknelpuntadvice,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/knelpuntadvice/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Put new knelpuntdetail advice
     * @returns newknelpuntadviceindex Success
     * @throws ApiError
     */
    public static async newknelpuntadvice({
id,
payload,
xFields,
}: {
/** An knelpuntdetail id **/
id: string,
payload: newknelpuntadvice,
/** An optional fields mask **/
xFields?: string,
}): Promise<newknelpuntadviceindex> {
        const result = await __request({
            method: 'PUT',
            path: `/knelpuntadvice/${id}`,
            headers: {
                'X-Fields': xFields,
            },
            body: payload,
        });
        return result.body;
    }

}