




















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { titleCaseToSentence } from '../Helper'
import { KnelpuntExtended } from '../../../../@types/KnelpuntExtended';

export default Vue.extend({
  data() {
    return {
      currentSort: "index",
      currentSortOrder: "asc",
      keys: [] as string[],
    }
  },
  methods: {
    titleCaseToSentence: titleCaseToSentence,
  },
  computed: {
    ...mapGetters([
      "filteredSelectedKnelpunten",
      "filterKnelpuntThemes",
      "involvedThemeOptions",
      "selectedTraces",
    ]),
    amountOfKnelpuntenPerTheme: {
      get(): { index: number; name: string; values: any;}[] {
        const map: Map<string, Map<string, number>> = new Map(); 
        //Map setup:
        ["Totaal", ...this.involvedThemeOptions].forEach((theme: string) => {
          const row = new Map<string, number>()
          this.keys = ["Totaal", ...(this.selectedTraces as string[])]
          //Add row items with initial value of 0
          this.keys.forEach((trace: string) => {
            row.set(trace, 0);
          });
          map[theme] = row;       
        });
        //Filling map with row data
        this.filteredSelectedKnelpunten.forEach((knelpuntExtended: KnelpuntExtended) => {
          if (map) {
            //Add up total amount of knelpunten
            map["Totaal"]!.set("Totaal", map["Totaal"]!.get("Totaal") + 1);
            map["Totaal"]!.set(knelpuntExtended.trace, map["Totaal"]!.get(knelpuntExtended.trace) + 1);
            const currentTheme = knelpuntExtended.knelpunt.theme;
            if (currentTheme == undefined) {
              //Add up remaining knelpunten without a type  
              map["overig"]!.set("Totaal", map["overig"]!.get("Totaal") + 1);
              map["overig"]!.set(knelpuntExtended.trace, map["overig"]!.get(knelpuntExtended.trace) + 1);
            } else {
              if (map[currentTheme] != undefined) {
                //Add up knelpunten with same type
                map[currentTheme]!.set("Totaal", map[currentTheme]!.get("Totaal") + 1);
                map[currentTheme]!.set(knelpuntExtended.trace, map[currentTheme]!.get(knelpuntExtended.trace) + 1);
              } else {
                throw "error: unsupported theme introduced " + currentTheme;
              }
            }
          }
        });
        //Return items
        return Object.keys(map).map((key: string, index: number) => {
          return {
            index: index + 1,
            name: key,
            values: map[key]
          }
        })
      },
      set(value: any): void {
        return;
      }
    },
    filterKnelpuntThemesItems: {
      get: function (): string[] {
        return this.filterKnelpuntThemes;
      },
      set: function (filterKnelpuntThemes: string[] | ""): void {
        this.$store.dispatch("handleUpdateFilterKnelpuntThemes", filterKnelpuntThemes);
      }
    },
  },
})
