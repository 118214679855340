

































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Trace } from '../../../../@gen'
import { getColorForPercentage } from '../Helper'

export default Vue.extend({
  data() {
    return {
      currentSort: "index",
      currentSortOrder: "asc",
    }
  },
  methods: {
    getColorForPercentage: getColorForPercentage,
    getPercentage(list: number[], value: number): number {
      return (list.indexOf(value) + 1) / list.length;    
    },
    rankedLabel(rank: number): string {
      return rank == 1 ? rank + "ste" : rank + "de";
    }
  },
  computed: {
    ...mapGetters([
      "tracesOverviews",
    ]),
    ...mapGetters({
      selectedTracesFromStore: "selectedTraces"
    }),
    //Prefense no setter warning:
    selectedTraces: {
      get(){
        return this.selectedTracesFromStore
      },
      set(newName){
        return newName
      } 
    },
    traceWeights(): number[] {
      const traceWeights: number[] = []
      this.tracesOverviews.forEach((traceOverview: Trace) => {
        if (traceOverview.totalWeight != undefined && traceWeights.indexOf(traceOverview.totalWeight) === -1) 
          traceWeights.push(traceOverview.totalWeight!);
      });
      return traceWeights.sort((a,b) => { return a - b });
    },
    traceKnelpuntenAmounts(): number[] {
      const traceKnelpuntenAmounts: number[] = [];
      this.tracesOverviews.forEach((traceOverview: Trace) => {
        if (traceOverview.totalKnelpunten != undefined && traceKnelpuntenAmounts.indexOf(traceOverview.totalKnelpunten) === -1) 
          traceKnelpuntenAmounts.push(traceOverview.totalKnelpunten!);
      });
      return traceKnelpuntenAmounts.sort((a,b) => { return a - b });
    }
  },
})
