


































import Vue from 'vue'
import { Modal } from '../../../../components'
import { getColorForPercentage } from '../Helper'
import { mapGetters } from 'vuex'
import { titleCaseToSentence } from '../Helper'


export default Vue.extend({
  components: {
    Modal
  },
  computed: {
    ...mapGetters([
      "knelpuntenanalyse"
    ]),
    weights(): number[] {
      if (this.knelpuntenanalyse != null) {
        return this.knelpuntenanalyse.weightConfig.map(item => item.weight)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((a,b) => { return a - b });
      } else {
        return []
      }
    }  
  },
  methods: {
    getColorForPercentage: getColorForPercentage,
    getWeightColor(weight: number): string {
      return getColorForPercentage(weight / this.weights[this.weights.length - 1], false);
    },
    titleCaseToSentence: titleCaseToSentence
  }
})
