
/**
 * Geo standard information interface.
 */
export interface GeoStandard {
  color: string
}

/**
 * Color mapping to theme's based on the Geo standard.
 */
export const GeoStandards: { [theme: string]: GeoStandard } = {
  "datatransport": {
    color: "#00ff00"
  },
  "gasLageDruk": {
    color: "#ffd750"
  },
  "gasHogeDruk": {
    color: "#ffaf3c"
  },
  "buisleidingGevaarlijkeInhoud": {
    color: "#ff7f00"
  },
  "landelijkHoogspanningsnet": {
    color: "#ff0000"
  },
  "hoogspanning": {
    color: "#ff0000"
  },
  "middenspanning": {
    color: "#c80000"
  },
  "laagspanning": {
    color: "#960000"
  },
  "petrochemie": {
    color: "#b64a00"
  },
  "chemie": {
    color: "#b64a00"
  },
  "rioolVrijverval": {
    color: "#ba38a8"
  },
  "rioolOnderOverOfOnderdruk": {
    color: "#800080"
  },
  "warmte": {
    color: "#008080"
  },
  "water": {
    color: "#0000ff"
  },
  "overig": {
    color: "#918a6f"
  },
  "default": {
    color: "#6f5c10"
  },
}


/**
 * Theme options included in the geo standard.
 */
export const GeoThemesOptions: string[] = Object.keys(GeoStandards)
  .filter((theme: string) => theme != "default")