




















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Knelpunt } from '../../../../@gen';
import { KnelpuntExtended } from '../../../../@types/KnelpuntExtended';
import { KnelpuntWeightSettingsOptions } from '../KnelpuntWeightSettings';

export default Vue.extend({
  data() {
    return {
      knelpuntStatusOptions: Object.values(Knelpunt.status), 
      currentSort: "index",
      currentSortOrder: "asc",
      keys: [] as string[],
    }
  },
  computed: {
    ...mapGetters([
      "filteredSelectedKnelpunten",
      "selectedTraces",
      "filterStatuses"
    ]),
    amountOfKnelpuntenPerStatus: {
      get(): { index: number; name: string; values: any;}[] {
        const map: Map<string, Map<string, number>> = new Map();
        //Map setup:
        ["Totaal", ...this.knelpuntStatusOptions].forEach((status: string) => {
          const row = new Map<string, number>()
          this.keys = ["Totaal", ...(this.selectedTraces as string[])]
          //Add row items with initial value of 0
          this.keys.forEach((trace: string) => {
            row.set(trace, 0);
          });
          map[status] = row;       
        });
        //Filling map with row data
        this.filteredSelectedKnelpunten.forEach((knelpunt: KnelpuntExtended) => {
          //Add up total amount of knelpunten
          map["Totaal"].set("Totaal", map["Totaal"]!.get("Totaal") + 1);
          map["Totaal"].set(knelpunt.trace, map["Totaal"]!.get(knelpunt.trace) + 1);
          if (knelpunt.knelpunt.status == undefined) {
            //Add up remaining knelpunten without a type  
            map["Overig"].set("Totaal", map["Overig"]!.get("Totaal") + 1);
            map["Overig"].set(knelpunt.trace, map["Overig"]!.get(knelpunt.trace) + 1);
          } else {
            //Add up knelpunten with same type
            const status: string = knelpunt.knelpunt.status!;
            if (map[status] != undefined) {
              map[status].set("Totaal", map[status]!.get("Totaal") + 1);
              map[status].set(knelpunt.trace, map[status]!.get(knelpunt.trace) + 1); 
            } else {
              throw "error: unsupported status introduced";
            }
          }
        });
        //Return items
        return Object.keys(map).map((key: string, index: number) => {
          return {
            index: index + 1,
            name: key,
            values: map[key]
          }
        })
      },
      set(value: string[]): void {
        return;
      }
    },
    filterStatusesItems: {
      get: function(): string[] {
        return this.filterStatuses;
      },
      set: function(filterStatuses: string[] | ""): void {
        this.$store.dispatch("handleUpdateFilterStatuses", filterStatuses);
      }
    },
  }
})
