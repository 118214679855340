/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KnelpuntDetail } from '../models/KnelpuntDetail';
import type { knelpuntwithnewdetail } from '../models/knelpuntwithnewdetail';
import type { newknelpuntdetailid } from '../models/newknelpuntdetailid';
import { request as __request } from '../core/request';

export class KnelpuntdetailService {

    /**
     * Get knelpuntdetail by id
     * @returns KnelpuntDetail Success
     * @throws ApiError
     */
    public static async knelpuntdetail({
id,
xFields,
}: {
/** An knelpuntdetail id **/
id: string,
/** An optional fields mask **/
xFields?: string,
}): Promise<KnelpuntDetail> {
        const result = await __request({
            method: 'GET',
            path: `/knelpuntdetail/${id}`,
            headers: {
                'X-Fields': xFields,
            },
        });
        return result.body;
    }

    /**
     * Get and save new knelpuntdetail id
     * @returns newknelpuntdetailid Success
     * @throws ApiError
     */
    public static async newknelpuntdetailid({
id,
payload,
xFields,
}: {
/** An knelpuntdetail id **/
id: string,
payload: knelpuntwithnewdetail,
/** An optional fields mask **/
xFields?: string,
}): Promise<newknelpuntdetailid> {
        const result = await __request({
            method: 'PUT',
            path: `/knelpuntdetail/${id}`,
            headers: {
                'X-Fields': xFields,
            },
            body: payload,
        });
        return result.body;
    }

}