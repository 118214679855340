


























































import Vue, { PropType } from 'vue'
import KnelpuntCard from './KnelpuntCard.vue'
import { KnelpuntExtended } from '../../../../../@types/KnelpuntExtended'
import { titleCaseToSentence } from '../../Helper'
import { Knelpunt, knelpuntstatusupdate, TracedetailService } from '../../../../../@gen'

export default Vue.extend({
  props: {
    knelpuntExtended: Object as PropType<KnelpuntExtended>,
  },
  components: {
    KnelpuntCard,
  },
  data () {
    return {
      knelpuntStatusOptions: Object.values(Knelpunt.status),
    }
  },
  methods: {
    titleCaseToSentence: titleCaseToSentence,
    updateKnelpuntStatus(payload: knelpuntstatusupdate) {
      TracedetailService.knelpuntstatus({payload: payload}).then()
    },
  },
  computed: {
    selectedKnelpuntStatus: {
      get: function (): string[] {
        return [this.knelpuntExtended.knelpunt.status!]
      },
      set: function (statuses: string[]) {
        if (statuses.length != 1) {
          statuses = statuses.filter((value: string) => value != this.knelpuntExtended.knelpunt.status)
          this.updateKnelpuntStatus({name: this.knelpuntExtended.trace, index: this.knelpuntExtended.index, status: statuses[0]} as knelpuntstatusupdate);
          this.knelpuntExtended.knelpunt.status = statuses[0] as Knelpunt.status | undefined;
        }
      }
    },
  }
})
