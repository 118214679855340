

































































import Vue, { PropType } from 'vue'
import { Modal } from '../../../../components'
import { titleCaseToSentence } from '../Helper'
import { Knelpunt, knelpuntstatusupdate, TracedetailService } from '../../../../@gen'
import { KnelpuntExtended } from '../../../../@types/KnelpuntExtended'

export default Vue.extend({
  components: {
    Modal,
  },
  props: {
    recommendedStatuses: {
      type: Array as PropType<string[]>,
      required: true,
    },
    knelpuntExtended: {
      type: Object as PropType<KnelpuntExtended>,
      required: true,
    },
  },
  created() {
    this.getOtherStatuses()
  },
  data () {
    return {
      knelpuntStatusOptions: Object.values(Knelpunt.status),
      status: undefined as Knelpunt.status | undefined,
      otherStatuses: [] as string[]
    }
  },
  computed: {
    selectedKnelpuntStatus: {
      get: function (): string[] {
        return [this.status as string]
      },
      set: function (statuses: string[]) {
        if (statuses.length != 1) {
          statuses = statuses.filter((value: string) => value != this.status)
          this.status = statuses[0] as Knelpunt.status | undefined;
        }
      }
    },
  },
  methods: {
    titleCaseToSentence: titleCaseToSentence,
    updateKnelpuntStatus(status: string) {
      TracedetailService.knelpuntstatus({payload: {name: this.knelpuntExtended.trace, index: this.knelpuntExtended.index, status: status} as knelpuntstatusupdate})
        .then(x => {
          this.knelpuntExtended.knelpunt.status = status as Knelpunt.status;
          this.$emit('close')
        });
    },
    getOtherStatuses() {
      this.otherStatuses = [...this.knelpuntStatusOptions].filter((statusOption: string) => {
        return !this.recommendedStatuses.includes(statusOption)
      })
    }
  },
  watch: {
    recommendedStatuses() {
      this.getOtherStatuses()
    }
  }
})
