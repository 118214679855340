/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { knelpuntstatusupdate } from '../models/knelpuntstatusupdate';
import type { TraceDetail } from '../models/TraceDetail';
import { request as __request } from '../core/request';

export class TracedetailService {

    /**
     * Update knelpunt status
     * @returns any Success
     * @throws ApiError
     */
    public static async knelpuntstatus({
payload,
}: {
payload: knelpuntstatusupdate,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/tracedetail/`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Get tracedetail by trace id
     * @returns TraceDetail Success
     * @throws ApiError
     */
    public static async tracedetail({
id,
xFields,
}: {
/** An tracedetail ID **/
id: string,
/** An optional fields mask **/
xFields?: string,
}): Promise<TraceDetail> {
        const result = await __request({
            method: 'GET',
            path: `/tracedetail/${id}`,
            headers: {
                'X-Fields': xFields,
            },
        });
        return result.body;
    }

}