/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { deleteknelpuntproblem } from '../models/deleteknelpuntproblem';
import type { newknelpuntproblem } from '../models/newknelpuntproblem';
import type { newknelpuntproblemindex } from '../models/newknelpuntproblemindex';
import type { updateknelpuntproblem } from '../models/updateknelpuntproblem';
import { request as __request } from '../core/request';

export class KnelpuntproblemService {

    /**
     * Delete knelpuntdetail problem
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteknelpuntproblem({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: deleteknelpuntproblem,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/knelpuntproblem/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Update knelpuntdetail problem
     * @returns any Success
     * @throws ApiError
     */
    public static async updateknelpuntproblem({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: updateknelpuntproblem,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/knelpuntproblem/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Put new knelpuntdetail problem
     * @returns newknelpuntproblemindex Success
     * @throws ApiError
     */
    public static async newknelpuntproblem({
id,
payload,
xFields,
}: {
/** An knelpuntdetail id **/
id: string,
payload: newknelpuntproblem,
/** An optional fields mask **/
xFields?: string,
}): Promise<newknelpuntproblemindex> {
        const result = await __request({
            method: 'PUT',
            path: `/knelpuntproblem/${id}`,
            headers: {
                'X-Fields': xFields,
            },
            body: payload,
        });
        return result.body;
    }

}