























































































































import Vue from 'vue'
import ToolbarHeader from "../../@layout/ToolbarHeader.vue"
import { mapGetters } from 'vuex'
import { Modal } from '../../../../components'
import KnelpuntenanalyseFilterPopover from './KnelpuntenanalyseFilterPopover.vue'
import KnelpuntenanalyseWeightConfigPopover from './KnelpuntenanalyseWeightConfigPopover.vue'
import { Knelpunt } from '../../../../@gen';

interface KnelpuntIndication {
  value: number,
  name: Knelpunt.status,
  tooltip: string,
  icon: string,
  color: string
}

export default Vue.extend({
  components: {
    ToolbarHeader,
    KnelpuntenanalyseFilterPopover,
    KnelpuntenanalyseWeightConfigPopover,
  },
  data () {
    return {
      weightConfigModal: false,
      filterConfigModal: false,
    }
  },
  computed: {
    selectedTracesItems: {
      get: function () {
        return this.selectedTraces;
      },
      set: function (selectedTraces: string[]) {
        this.$store.dispatch("handleUpdateSelectedTraces", selectedTraces);
      }
    },
    ...mapGetters([
      "amountOfActiveFilters",
      "selectedTraces",
      "traceNames",
      "filterStatuses",
      "amountOfOpenKnelpunten",
      "amountOfBesprekingKnelpunten",
      "amountOfActieKnelpunten",
    ]),
    knelpuntIndications(): KnelpuntIndication[] {
      const indications: KnelpuntIndication[] = []
      if (this.amountOfOpenKnelpunten > 0) {
        indications.push({
          value: this.amountOfOpenKnelpunten,
          name: Knelpunt.status.OPEN_NOG_GEEN_ACTIE_ONDERNOMEN,
          tooltip: "Open - Nog geen actie ondernomen",
          icon: "search",
          color: "rgb(60, 72, 88)"
        } as KnelpuntIndication)
      }
      if (this.amountOfBesprekingKnelpunten > 0) {
        indications.push({
          value: this.amountOfBesprekingKnelpunten,
          name: Knelpunt.status.IN_OPVOLGING_IN_BESPREKING,
          tooltip: "In opvolging - In bespreking",
          icon: "speaker_notes",
          color: "rgb(60, 72, 88)"
        } as KnelpuntIndication)
      }
         if (this.amountOfActieKnelpunten > 0) {
        indications.push({
          value: this.amountOfActieKnelpunten,
          name: Knelpunt.status.IN_OPVOLGING_ACTIE_VEREIST,
          tooltip: "In opvolging - Actie vereist",
          icon: "warning",
          color: "rgb(255, 102, 102)"
        } as KnelpuntIndication)
      }
      return indications
    }
  },
  methods: {
    filterIncludesStatus(status: string): boolean {
      return (this.filterStatuses as string[]).includes(status)
    },
    addStatusFilter(status: string) {
      let newFilterStatuses: string[] = [...this.filterStatuses];
      if (!(this.filterStatuses as string[]).includes(status)) {
        newFilterStatuses.push(status)
      } else {
        newFilterStatuses = newFilterStatuses.filter(value => value !== status)
      }
      this.$store.dispatch("handleUpdateFilterStatuses", newFilterStatuses);
    }
  }
})
