























import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ProjectLink } from '../../../store/project'

export default Vue.extend({
   computed: {
    ...mapGetters([
      "projects",
    ]),
  },
  methods: {
    getProjectTitle(projectLink: ProjectLink) {
      return projectLink.projectType.charAt(0).toUpperCase() + projectLink.projectType.slice(1) + ' ' + projectLink.code;
    }
  }
})
