


















import Vue from 'vue'
import { KnelpuntDetailType, KnelpuntDetailTypes } from '../../KnelpuntDetailTypes'

// Styling for knelpunt infromation
export default Vue.extend({
  props: {
    typeCard: String,
    footer: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: -1,
    }
  },
  computed: {
    item(): KnelpuntDetailType {
      return KnelpuntDetailTypes[this.typeCard as string]
    },
    id(): string {
      return this.index != -1 ? this.item.name + this.index.toString() : this.item.name;
    }
  },
})
