/**
 * Infromation for rendering knelpunt detail type.
 */
export interface KnelpuntDetailType {
  name: string;
  icon: string;
  color: string;
  multiple: boolean;
}
//TODO: Support all detail types
/**
 * Object with map of infromation for rendering knelpunt detail types.
 */
export const KnelpuntDetailTypes: { [type: string]: KnelpuntDetailType } = {
  "Informatie": {
    name: "Informatie",
    icon: "info",
    color: "#33CCFF",
    multiple: false
  },
  "Opmerking": {
    name: "Opmerking",
    icon: "comment",
    color: "#FF9966",
    multiple: false
  },
  "Meting": {
    //name: "Meting",
    name: "Meting (In ontwikkeling)",
    icon: "bar_chart",
    //color: "#33CC00",
    color: "#999999",
    multiple: true
  },
  "Document": {
    //name: "Document",
    name: "Document (In ontwikkeling)",
    icon: "insert_drive_file",
    //color: "#3366FF",
    color: "#999999",
    multiple: true
  },
  "Probleem": {
    name: "Probleem",
    icon: "warning",
    color: "#FF6666",
    multiple: false
  },
  "Advies": {
    name: "Advies",
    icon: "help",
    color: "#FF99FF",
    multiple: false
  },
  "Oplossing": {
    name: "Oplossing",
    icon: "outlined_flag",
    color: "#66FF99",
    multiple: false
  },
  "Waarde weerlegging": {
    //name: "Waarde weerlegging",
    name: "Waarde weerlegging (In ontwikkeling)",
    icon: "import_export", //height /tune
    //color: "#FF6666",
    color: "#999999",
    multiple: false
  },
  "Custom": {
    //name: "Custom",
    name: "Custom (In ontwikkeling)",
    icon: "edit",
    //color: "#FFCC66",
    color: "#999999",
    multiple: true
  },
};

/*
export const KnelpuntDetailTypes: { [type: string]: KnelpuntDetailType } = {
  "Informatie": {
    name: "Informatie",
    icon: "info",
    color: "#33CCFF",
    multiple: false
  },
  "Opmerking": {
    name: "Opmerking",
    icon: "comment",
    color: "#FF9966",
    multiple: false
  },
  "Meting": {
    name: "Meting",
    icon: "bar_chart",
    color: "#33CC00",
    multiple: true
  },
  "Document": {
    name: "Document",
    icon: "insert_drive_file",
    color: "#3366FF",
    multiple: true
  },
  "Probleem": {
    name: "Probleem",
    icon: "warning",
    color: "#FF6666",
    multiple: false
  },
  "Advies": {
    name: "Advies",
    icon: "help",
    color: "#FF99FF",
    multiple: false
  },
  "Oplossing": {
    name: "Oplossing",
    icon: "outlined_flag",
    color: "#66FF99",
    multiple: false
  },
  "Waarde weerlegging": {
    name: "Waarde weerlegging",
    icon: "import_export", //height /tune
    color: "#FF6666",
    multiple: false
  },
  "Custom": {
    name: "Custom",
    icon: "edit",
    color: "#FFCC66",
    multiple: true
  },
};
*/