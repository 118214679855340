
































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Modal } from '../../../../components'
import { KnelpuntWeightSettingsOptions } from '../KnelpuntWeightSettings'
import { titleCaseToSentence } from '../Helper'
import { Knelpunt } from '../../../../@gen'

export default Vue.extend({
  components: {
    Modal,
  },
  data () {
    return {
      knelpuntStatusOptions: Object.values(Knelpunt.status), 
      filterKnelpuntRisksOptions: KnelpuntWeightSettingsOptions,
      filterMaxWeightIsNumber: true,
      filterMinWeightIsNumber: true,
    }
  },
  computed: {
    // Filter options per argument catagory:
    filterKnelpuntThemesItems: {
      get: function (): string[] {
        return this.filterKnelpuntThemes;
      },
      set: function (filterKnelpuntThemes: string[] | "") {
        this.$store.dispatch("handleUpdateFilterKnelpuntThemes", filterKnelpuntThemes);
      }
    },
    filterKnelpuntRisksItems: {
      get: function(): string[] {
        return this.filterKnelpuntRisks;
      },
      set: function(filterKnelpuntRisks: string[] | ""): void {
        this.$store.dispatch("handleUpdateFilterKnelpuntRisks", filterKnelpuntRisks);
      }
    },
    filterNetbeheerdersItems: {
      get: function(): string[] {
        return this.filterNetbeheerders;
      },
      set: function(filterNetbeheerders: string[] | ""): void {
        this.$store.dispatch("handleUpdateFilterNetbeheerders", filterNetbeheerders);
      }
    },
    filterMinWeightItem: {
      get: function(): number {
        return this.filterMinWeight;
      },
      set: function(filterMinWeight: number): void {
        if (!isNaN(filterMinWeight)) {
          this.filterMinWeightIsNumber = true;
          this.$store.dispatch("handleUpdateFilterMinWeight", filterMinWeight);
        } else {
          this.filterMinWeightIsNumber = false;
        }
      }
    },
    filterMaxWeightItem: {
      get: function(): number {
        return this.filterMaxWeight;
      },
      set: function(filterMaxWeight: number): void {
        if (!isNaN(filterMaxWeight)) {
          this.filterMaxWeightIsNumber = true;
          this.$store.dispatch("handleUpdateFilterMaxWeight", filterMaxWeight);
        } else {
          this.filterMaxWeightIsNumber = false;
        }
      }
    },
    filterStatusesItems: {
      get: function(): string[] {
        return this.filterStatuses;
      },
      set: function(filterStatuses: string[] | ""): void {
        this.$store.dispatch("handleUpdateFilterStatuses", filterStatuses);
      }
    },
    ...mapGetters([
      "amountOfActiveFilters",
      "filterKnelpuntThemes",
      "filterKnelpuntRisks",
      "involvedNetbeheerdersOptions",
      "involvedThemeOptions",
      "filterNetbeheerders",
      "filterMinWeight",
      "filterMaxWeight",
      "filterStatuses"
    ]),
  },
  methods: {
    titleCaseToSentence: titleCaseToSentence,
    clearFilters() {
      this.$store.dispatch("handleClearFilters");
    },
  },
})
