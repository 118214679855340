/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { deleteknelpuntcomment } from '../models/deleteknelpuntcomment';
import type { newknelpuntcomment } from '../models/newknelpuntcomment';
import type { newknelpuntcommentindex } from '../models/newknelpuntcommentindex';
import type { updateknelpuntcomment } from '../models/updateknelpuntcomment';
import { request as __request } from '../core/request';

export class KnelpuntcommentService {

    /**
     * Delete knelpuntdetail comment
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteknelpuntcomment({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: deleteknelpuntcomment,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/knelpuntcomment/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Update knelpuntdetail comment
     * @returns any Success
     * @throws ApiError
     */
    public static async updateknelpuntcomment({
id,
payload,
}: {
/** An knelpuntdetail id **/
id: string,
payload: updateknelpuntcomment,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/knelpuntcomment/${id}`,
            body: payload,
        });
        return result.body;
    }

    /**
     * Put new knelpuntdetail comment
     * @returns newknelpuntcommentindex Success
     * @throws ApiError
     */
    public static async newknelpuntcomment({
id,
payload,
xFields,
}: {
/** An knelpuntdetail id **/
id: string,
payload: newknelpuntcomment,
/** An optional fields mask **/
xFields?: string,
}): Promise<newknelpuntcommentindex> {
        const result = await __request({
            method: 'PUT',
            path: `/knelpuntcomment/${id}`,
            headers: {
                'X-Fields': xFields,
            },
            body: payload,
        });
        return result.body;
    }

}